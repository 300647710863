*{
  margin:0px;
  padding:0px;
  box-sizing: content-box;
  /* width:100%; */
  }
.formcontainer {
    width: 400px;
    /* background: linear-gradient(#212121, #212121) padding-box,
                linear-gradient(145deg, transparent 35%,#e81cff, #40c9ff) border-box; */
    border: 2px solid transparent;
    padding: 32px 24px;
    font-size: 14px;
    font-family: inherit;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 16px;
  }
  
  .formcontainer button:active {
    scale: 0.95;
  }
  
  .formcontainer .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .formcontainer .formgroup {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .formcontainer .formgroup label {
    display: block;
    margin-bottom: 5px;
    color: #717171;
    font-weight: 600;
    font-size: 12px;
  }
  
  .formcontainer .formgroup input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    color: #fff;
    font-family: inherit;
    background-color: transparent;
    border: 1px solid #414141;
    margin-left: -15px;
  }
  
  .formcontainer .formgroup textarea {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    resize: none;
    color: #fff;
    height: 96px;
    border: 1px solid #414141;
    background-color: transparent;
    font-family: inherit;
    margin-left: -15px;
  }
  
  .formcontainer .formgroup input::placeholder {
    opacity: 0.5;
  }
  
  .formcontainer .formgroup input:focus {
    outline: none;
    border-color: #e81cff;
  }
  
  .formcontainer .formgroup textarea:focus {
    outline: none;
    border-color: #e81cff;
  }
  
  .formcontainer .formsubmitbtn {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    font-family: inherit;
    color: #717171;
    font-weight: 600;
    width: 40%;
    background: #313131;
    border: 1px solid #414141;
    padding: 12px 16px;
    font-size: inherit;
    gap: 8px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 6px;
  }
  
  .formcontainer .formsubmitbtn:hover {
    background-color: #fff;
    border-color: #fff;
  }
  
  
  
  