*{
margin:0px;
padding:0px;
box-sizing: content-box;

}
.container{
    display:flex;
    /* margin-top: 120PX; */
}
/* main{ */
    /* width: 100%; */
    /* padding: 120px; */
/* } */
.sidebar{
    background: #000;
    color: #fff;
    height: 220vh;
    width: 500px;
    transition: all 0.5s;
    margin-left: -14px;
}
.top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;

}
.logo{
    font-size: 30px;
    justify-content: center;
    gap: 45px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover{
    background:lightskyblue;
    color: #000;
    transition: all 0.5s;
}
.active{
    background: lightskyblue;
    color: #000;
}
.icon, .link_text{
    font-size: 20px;
}


